import {
  addWidgetOptions,
  ComponentLayoutObject,
  WidgetInstallationType,
} from '@wix/platform-editor-sdk';

const MAIN_WIDGET_WITH_GALLERY_ID = '45160b6f-e525-4771-b7c4-a6921d3e7447';
const MAIN_WIDGET_WITH_GALLERY_PRESET_ID = 'variants-lxkjulgo1';

const MAIN_WIDGET_WITH_SLOT_ID = 'acd45a6e-be7c-4b7e-a7d4-ba8d9709a317';
const MAIN_WIDGET_WITH_SLOT_PRESET_ID = 'variants-lxhbwiok1';

const layoutsForStudio: Partial<ComponentLayoutObject> = {
  componentLayout: {
    type: 'ComponentLayout',
    hidden: false,
    width: { type: 'percentage', value: 100 },
    height: { type: 'auto' },
    maxHeight: { type: 'percentage', value: 100 },
  },
  itemLayout: {
    id: '',
    type: 'GridItemLayout',
    gridArea: {
      rowStart: 1,
      rowEnd: 2,
      columnStart: 1,
      columnEnd: 2,
    },
    alignSelf: 'start',
    justifySelf: 'start',
  },
};

export const mainWidgetOptions = (isResponsive: boolean): addWidgetOptions => ({
  widgetId: MAIN_WIDGET_WITH_SLOT_ID,
  installationType: 'closed',
  layout: {
    fixedPosition: false,
    // @ts-expect-error
    docked: {
      left: {
        px: 0,
        vw: 0,
      },
      right: {
        px: 0,
        vw: 0,
      },
    },
    width: 900,
    height: 1080,
    x: 0,
    y: 0,
  },
  presetIds: {
    layout: MAIN_WIDGET_WITH_SLOT_PRESET_ID,
    style: MAIN_WIDGET_WITH_SLOT_PRESET_ID,
  },
  layouts: isResponsive ? layoutsForStudio : undefined,
});

import type { GetWidgetManifestFn } from '@wix/yoshi-flow-editor/blocks';
import { ArticlesIds, WIDGETS_NAMES } from '../../utils/constants';

export const getWidgetManifest: GetWidgetManifestFn = (widgetBuilder) => {
  widgetBuilder
    .set({
      displayName: WIDGETS_NAMES.mainWidget.displayName,
      nickname: WIDGETS_NAMES.mainWidget.nickName,
    })
    .behavior()
    .set({ removable: false });

  widgetBuilder.gfpp().set('help', {
    id: ArticlesIds.mainWidget,
  });
};

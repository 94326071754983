import type { GetWidgetManifestFn } from '@wix/yoshi-flow-editor/blocks';

export const getWidgetManifest: GetWidgetManifestFn = (widgetBuilder) => {
  widgetBuilder
    .set({
      displayName: 'Widget List item widget new',
      nickname: 'List item widget newWidget',
    })
    .behavior()
    .set({ removable: false });
};

import type { InitialAppData } from '@wix/platform-editor-sdk';
import { installer } from './editor/installer';
import {
  EditorSDK,
  Exports,
} from '@wix/editor-platform-sdk-types/dist/types/editor-app';
import { fetchWidgetsStageData } from '@wix/bob-widget-services';
import { initGfppEventListener } from './editor/panels/gfppEventListeners';
import {
  EditorReadyFn,
  GetAppManifestFn,
  WidgetBuilder,
} from '@wix/yoshi-flow-editor';
import { MAIN_WIDGET_CONTROLLER_ID } from './utils/constants';
import {
  SEARCH_LIGHTBOX_CONTROLLER_TYPE,
  SEARCH_LIGHTBOX_TPA_PAGE_ID,
} from '@wix/site-search-common';

type EditorAppContext = {
  editorSDK: EditorSDK;
};
let context: EditorAppContext;
export const editorReady: EditorReadyFn = async (
  editorSDK,
  appDefId,
  options,
  flowAPI,
) => {
  context = { editorSDK };
  if (options.firstInstall) {
    await installer(editorSDK, appDefId, flowAPI, options);
  }
  await initGfppEventListener(editorSDK, flowAPI, options);
};

export const getAppManifest: GetAppManifestFn = async (options) => {
  const { appManifestBuilder } = options;

  const baseManifest = await fetchWidgetsStageData({
    initialAppData: options as unknown as InitialAppData,
    editorSDK: context.editorSDK,
  });

  const appManifest = appManifestBuilder
    .configureWidget(
      MAIN_WIDGET_CONTROLLER_ID,
      (widgetBuilder: WidgetBuilder) => {
        widgetBuilder
          .gfpp()
          .set('connect', {
            behavior: 'HIDE',
          })
          .set('widgetPlugins', {
            behavior: 'HIDE',
          });
      },
    )
    .configureController(SEARCH_LIGHTBOX_CONTROLLER_TYPE, (controllerBuilder) =>
      controllerBuilder.configureConnectedComponents(
        SEARCH_LIGHTBOX_TPA_PAGE_ID,
        (connectedComponentsBuilder) => {
          connectedComponentsBuilder.behavior();
          connectedComponentsBuilder
            .gfpp()
            .set('layout', { behavior: 'HIDE' })
            .set('mainAction1', { behavior: 'DEFAULT' })
            .set('mainAction2', { behavior: 'HIDE' })
            .set('settings', { behavior: 'HIDE' });
        },
      ),
    )
    .withJsonManifest(baseManifest)
    .build();

  return appManifest;
};

export const exports: Exports = (editorSDK: EditorSDK) => ({
  public: {
    uninstallApp: async () => {
      await editorSDK.application.uninstall('token', {
        openConfirmation: false,
      });
    },
  },
});

import { EditorSDK, PopupPageDefinition } from '@wix/platform-editor-sdk';
import { SEARCH_APP_APP_DEF_ID } from '@/utils/constants';
import {
  SEARCH_LIGHTBOX_TPA_PAGE_ID,
  SEARCH_LIGHTBOX_CONTROLLER_TYPE,
} from '@wix/site-search-common';

const lightboxBackground = {
  custom: true,
  isPreset: false,
  ref: {
    type: 'BackgroundMedia',
    color: '#000',
    colorOpacity: 0.3,
    alignType: 'top',
    fittingType: 'fill',
    scrollType: 'fixed',
    colorOverlay: '',
    colorOverlayOpacity: 0,
  },
};

export const lightboxOptions = async ({
  appDefinitionId,
  editorSDK,
  lightboxTitle,
}: {
  appDefinitionId: string;
  editorSDK: EditorSDK;
  lightboxTitle: string;
}) => {
  const tpaApplicationId = (
    await editorSDK.tpa.app.getDataByAppDefId('', SEARCH_APP_APP_DEF_ID)
  ).applicationId;

  return {
    title: lightboxTitle,
    hideTitle: false,
    controllerType: SEARCH_LIGHTBOX_CONTROLLER_TYPE,
    popupRole: SEARCH_LIGHTBOX_TPA_PAGE_ID,
    shouldNavigateToPage: false,
    definition: {
      data: {
        appDefinitionId,
        managingAppDefId: SEARCH_APP_APP_DEF_ID,
        tpaPageId: SEARCH_LIGHTBOX_TPA_PAGE_ID,
        tpaApplicationId,
        pageBackgrounds: {
          desktop: lightboxBackground,
          mobile: lightboxBackground,
        },
      },
      style: {
        type: 'ComponentStyle',
        styleType: 'custom',
        componentClassName: 'mobile.core.components.Page',
        style: {
          properties: {
            'alpha-bg': '0.3',
            bg: 'color_15',
          },
          propertiesSource: {
            'alpha-bg': 'value',
            bg: 'theme',
          },
          groups: {},
        },
        skin: 'wysiwyg.viewer.skins.page.ResponsivePageWithColorBG',
      },
      components: [
        {
          type: 'Container',
          componentType: 'wysiwyg.viewer.components.PopupContainer',
          props: { type: 'PopupContainerProperties' },
          skin: 'wysiwyg.viewer.skins.area.RectangleArea',
          layout: {
            height: 1080,
            width: 900,
            x: 0,
            y: 0,
          },
          layouts: {
            type: 'SingleLayoutData',
            containerLayout: {
              type: 'GridContainerLayout',
              rows: [{ type: 'fr', value: 1 }],
              columns: [{ type: 'fr', value: 1 }],
              overflowX: 'auto',
              overflowY: 'auto',
            },
            componentLayout: {
              type: 'ComponentLayout',
              hidden: false,
              height: { type: 'auto' },
              maxHeight: { type: 'vh', value: 100 },
              width: { type: 'percentage', value: 100 },
            },
            itemLayout: {
              id: '',
              type: 'FixedItemLayout',
              margins: {
                left: { type: 'px', value: 0 },
                right: { type: 'px', value: 0 },
                top: { type: 'px', value: 0 },
                bottom: { type: 'px', value: 0 },
              },
              justifySelf: 'start',
              alignSelf: 'start',
            },
          },
        },
      ],
    } as Partial<PopupPageDefinition>,
  };
};

import type { GetWidgetManifestFn } from '@wix/yoshi-flow-editor/blocks';
import { WIDGETS_NAMES } from '../../utils/constants';

export const getWidgetManifest: GetWidgetManifestFn = (widgetBuilder) => {
  widgetBuilder
    .configureConnectedComponents('searchBox', (connectedComponentsBuilder) => {
      connectedComponentsBuilder
        .panel<'SearchBox'>('settings')
        .configureControls({ displaySettingsPanelToggles: { hidden: true } });
    })
    .configureConnectedComponents(
      'closeButton',
      (connectedComponentsBuilder) => {
        connectedComponentsBuilder
          .panel<'StylableButton'>('settings')
          .configureControls({
            link: {
              hidden: true,
            },
            icon: {
              hidden: false,
            },
            label: {
              hidden: false,
            },
          });
      },
    )
    .configureConnectedComponents(
      'udpCloseButton',
      (connectedComponentsBuilder) => {
        connectedComponentsBuilder
          .panel<'StylableButton'>('settings')
          .configureControls({
            link: {
              hidden: true,
            },
            icon: {
              hidden: false,
            },
            label: {
              hidden: false,
            },
          });
      },
    )
    .set({
      displayName: WIDGETS_NAMES.searchHeaderSideBySide.displayName,
      nickname: WIDGETS_NAMES.searchHeaderSideBySide.nickName,
    })
    .behavior()
    .set({ removable: false });
};

import type { GetWidgetManifestFn } from '@wix/yoshi-flow-editor/blocks';
import { WIDGETS_NAMES } from '../../utils/constants';

export const getWidgetManifest: GetWidgetManifestFn = (widgetBuilder) => {
  widgetBuilder
    .set({
      displayName: WIDGETS_NAMES.searchSuggestionsList.displayName,
      nickname: WIDGETS_NAMES.searchSuggestionsList.nickName,
    })
    .behavior()
    .set({ removable: false });
};

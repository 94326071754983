import {
  EditorScriptFlowAPI,
  FlowPlatformOptions,
} from '@wix/yoshi-flow-editor';
import { PanelsApiFactory } from '@wix/blocks-widget-services/panels';
import { EditorSDK } from '@wix/platform-editor-sdk';

const EVENT_LISTENER_GFPP_IDS = {
  suggestionSettings: 'suggestionSettings',
  elementsPanel: 'elementsPanel',
  footerLayout: 'footerLayout',
  headerLayout: 'headerLayout',
};

export const initGfppEventListener = async (
  editorSDK: EditorSDK,
  flowAPI: EditorScriptFlowAPI,
  options: FlowPlatformOptions,
) => {
  const panelsApi = await new PanelsApiFactory().createPanelsApi({
    editorSDK,
    editorType: options.origin.type,
    essentials: options.essentials,
  });

  const {
    translations: { t },
  } = flowAPI;

  return editorSDK.addEventListener('widgetGfppClicked', (event) => {
    const { id, componentRef } = event.detail;

    switch (id) {
      case EVENT_LISTENER_GFPP_IDS.suggestionSettings:
        panelsApi.openBlocksPanel('Suggestion Settings', componentRef, {
          overrides: {
            title: t('siteSearch.viewer.suggestionSettings.title'),
          },
        });
        break;
      case EVENT_LISTENER_GFPP_IDS.elementsPanel:
        panelsApi.openBlocksPanel('Suggestion Elements', componentRef, {
          overrides: {
            title: t('siteSearch.viewer.suggestionElements.title'),
          },
        });
        break;
      case EVENT_LISTENER_GFPP_IDS.footerLayout:
        panelsApi.openBlocksPanel('Footer Layout', componentRef, {
          overrides: {
            title: t('siteSearch.viewer.footerLayout.title'),
          },
        });
        break;
      case EVENT_LISTENER_GFPP_IDS.headerLayout:
        panelsApi.openBlocksPanel('Search Modal Header', componentRef, {
          overrides: {
            title: t('siteSearch.viewer.headerLayout.title'),
          },
        });
        break;
    }
  });
};

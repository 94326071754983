import type { GetWidgetManifestFn } from '@wix/yoshi-flow-editor/blocks';
import { WIDGETS_NAMES } from '../../utils/constants';

export const getWidgetManifest: GetWidgetManifestFn = (widgetBuilder) => {
  widgetBuilder
    .configureConnectedComponents('searchBox', (connectedComponentsBuilder) => {
      connectedComponentsBuilder
        .panel<'SearchBox'>('settings')
        .configureControls({ displaySettingsPanelToggles: { hidden: true } });
    })
    .set({
      displayName: WIDGETS_NAMES.searchBar.displayName,
      nickname: WIDGETS_NAMES.searchBar.nickName,
    })
    .behavior()
    .set({ removable: false });
};

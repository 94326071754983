import { installScriptBuilder } from '@wix/bob-widget-services';
import {
  type EditorSDK,
  EditorType,
  ContextParams,
} from '@wix/platform-editor-sdk';
import { mainWidgetOptions } from './definitions/main-widget';
import { lightboxOptions } from './definitions/lightbox';
import { SEARCH_APP_APP_DEF_ID } from '../utils/constants';
import { EditorScriptFlowAPI } from '@wix/yoshi-flow-editor';

import type { FlowEditorSDK, TFunction } from '@wix/yoshi-flow-editor';

const createSuggestionsLightbox = async (
  editorSDK: FlowEditorSDK,
  appDefinitionId: string,
  t: TFunction,
) => {
  const popupRef = await editorSDK.document.pages.popupPages.addConnected(
    '',
    await lightboxOptions({
      appDefinitionId,
      editorSDK,
      lightboxTitle: t('siteSearch.pages.suggestion.pageTitle'),
    }),
  );

  editorSDK.document.pages.popupPages.updateBehavior('', {
    popupRef,
    behavior: {
      name: 'openPopup',
      params: {
        delay: 0,
        openInDesktop: false,
        openInMobile: false,
      },
    },
  });

  const [lightboxCompRef] = await editorSDK.document.components.getChildren(
    '',
    { componentRef: popupRef },
  );

  return lightboxCompRef ?? popupRef;
};

const addLightboxWithSuggestionsModal = async (
  editorSDK: FlowEditorSDK,
  isResponsive: boolean,
  appDefinitionId: string,
  t: TFunction,
) => {
  const lightboxCompRef = await createSuggestionsLightbox(
    editorSDK,
    appDefinitionId,
    t,
  );

  return editorSDK.application.appStudioWidgets.addWidget('', {
    containerRef: lightboxCompRef,
    ...mainWidgetOptions(isResponsive),
  });
};

export const installer = installScriptBuilder('Installing Search Suggestions')
  .withStep('Adding Suggestions Modal', addSuggestionsModal)
  .build();

async function integrateSiteSearch(editorSDK: EditorSDK) {
  const isSearchAppInstalled =
    await editorSDK.application.isApplicationInstalled('', {
      appDefinitionId: SEARCH_APP_APP_DEF_ID,
    });
  if (!isSearchAppInstalled) {
    await editorSDK.application.add('', {
      appDefinitionId: SEARCH_APP_APP_DEF_ID,
    });
  }
}

async function addSuggestionsModal(
  editorSDK: EditorSDK,
  appDefinitionId: string,
  _: any,
  flowAPI: EditorScriptFlowAPI,
  options: ContextParams,
) {
  const isResponsive = options.origin.type === EditorType.Responsive;

  const { t } = flowAPI.translations;

  await addLightboxWithSuggestionsModal(
    editorSDK,
    isResponsive,
    appDefinitionId,
    t as any,
  );
}
